<script>
import ui from '/~/core/ui'
import { getCashbackRebate } from '/~/utils/rebates'
import DetailsCashPrint from '/~/components/activity/details/cash-print/details-cash-print.vue'
import DetailsCashbackPrint from '/~/components/activity/details/cashback-print/details-cashback-print.vue'
import DetailsOrderPrint from '/~/components/activity/details/order-print/details-order-print.vue'
import DetailsQrPrint from '/~/components/activity/details/qr-print/details-qr-print.vue'
import AppLogo from '/~/components/app/app-logo.vue'
import BaseButton from '/~/components/base/button/base-button.vue'
import BaseLoader from '/~/components/base/loader/base-loader.vue'
import CashbackPanel from '/~/components/cashback/cashback-panel.vue'
import PointsPanel from '/~/components/points/points-panel.vue'
import { useActivity } from '/~/composables/activity'
import { useCms } from '/~/composables/cms/use-cms'
import { usePoints } from '/~/composables/points'
import ActivityDetailCash from './cash/drawer-activity-detail-cash.vue'
import ActivityDetailCashback from './cashback/drawer-activity-detail-cashback.vue'
import ActivityDetailOrder from './order/drawer-activity-detail-order.vue'
import ActivityDetailPayTo from './pay/drawer-activity-detail-pay-to.vue'
import ActivityDetailQr from './qr/drawer-activity-detail-qr.vue'
import ActivityDetailTopUp from './top-up/drawer-activity-detail-top-up.vue'

export default {
  name: 'drawer-activity-details',
  components: {
    AppLogo,
    ActivityDetailOrder,
    DetailsOrderPrint,
    DetailsQrPrint,
    ActivityDetailCashback,
    DetailsCashbackPrint,
    ActivityDetailCash,
    DetailsCashPrint,
    ActivityDetailPayTo,
    ActivityDetailTopUp,
    ActivityDetailQr,
    BaseButton,
    BaseLoader,
    PointsPanel,
    CashbackPanel,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
  },
  setup() {
    const {
      calculatePointsEarnedForPayment,
      calculatePointsEarnedForPurchase,
    } = usePoints()
    const { getActivityItem, getFlowOrderItem } = useActivity()
    const { ewalletLabels } = useCms()

    return {
      calculatePointsEarnedForPayment,
      calculatePointsEarnedForPurchase,
      getActivityItem,
      getFlowOrderItem,
      ui,
      ewalletLabels,
    }
  },
  data() {
    return {
      detailsLoading: true,
      item: {},
      order: null,
    }
  },
  computed: {
    content() {
      if (
        (this.item.isTypePurchaseOrder || this.item.isTypeQrPayOrder) &&
        this.order
      ) {
        return this.order
      }

      return this.item
    },
    config() {
      const config = {
        baseURL: eonx.hosts.api.v1_1,
      }

      return config
    },
    showPrintButton() {
      const isPrintButton =
        !this.item.isTypeCashbackReconciliationFeed &&
        !this.item.isTypePointsTransaction &&
        !this.item.isTypeQrPayOrder &&
        !this.item.isTypeTopUpTransaction

      return isPrintButton && !ui.mobile && !this.showPayTo
    },
    showPayTo() {
      return this.item.isTypePaymentOrder
    },
    showTopUp() {
      return this.item.isTypeTopUpTransaction
    },
    showOrderDetails() {
      return this.item.isTypePurchaseOrder && Boolean(this.order)
    },
    showCashBackDetails() {
      return this.item.isTypeCashbackReconciliationFeed
    },
    showCashDetails() {
      return this.item.isTypeCashTransaction
    },
    showQrPayDetails() {
      return this.item.isTypeQrPayOrder
    },
    status() {
      return (this.order?.status ?? '').toLowerCase()
    },
    financialStatus() {
      return (this.order?.financialStatus ?? '').toLowerCase()
    },
    isCompleted() {
      return (
        this.financialStatus === 'paid' &&
        ['processing', 'completed'].includes(this.status)
      )
    },
    pointsEarned() {
      const pointsTransactions = this.order?.pointsTransactions ?? []
      const earnTransaction = pointsTransactions.filter(
        (t) => t.type === 'earn'
      )

      if (earnTransaction.length > 0) {
        return earnTransaction[0].allocatedValue
      }

      return this.order?.isTypePurchaseOrder
        ? this.calculatePointsEarnedForPurchase(this.order?.items ?? [])
        : this.calculatePointsEarnedForPayment(this.order?.subtotal ?? 0)
    },
    isPointsEarnShow() {
      if (this.item.isTypePointsTransaction) {
        return true
      }
      if (this.item.isTypePurchaseOrder) {
        return this.isPurchaseOrderPoints
      }
      if (
        this.item.isTypePaymentOrder ||
        this.item.isTypeStatementOrder ||
        this.item.isTypeBatchOrder
      ) {
        return this.isPaymentOrderPoints
      }
      return false
    },
    cashbackRebateTotal() {
      return getCashbackRebate(this.order.rebateTotalByType)
    },
  },
  created() {
    this.getActivityDetails()
  },
  methods: {
    getOrder() {
      return this.getFlowOrderItem(this.item.flowType, this.item.orderNumber)
    },
    async getActivityDetails() {
      this.detailsLoading = true

      try {
        this.item = await this.getActivityItem(this.id, this.config)

        this.order = await this.getOrder()
      } catch (error) {
        console.error(error)
        this.$emit('back')
      } finally {
        this.detailsLoading = false
      }
    },
    print() {
      window.print()
    },
  },
}
</script>

<template>
  <div
    v-if="detailsLoading"
    class="flex h-full w-full items-center justify-center"
  >
    <base-loader fullwidth size="xl" />
  </div>
  <div v-else class="flex min-h-full flex-1 flex-col px-5">
    <activity-detail-pay-to
      v-if="showPayTo"
      :content="content"
      :details-loading="detailsLoading"
    />

    <activity-detail-top-up
      v-if="showTopUp"
      :content="content"
      :details-loading="detailsLoading"
    />

    <activity-detail-order
      v-if="showOrderDetails"
      :content="content"
      :details-loading="detailsLoading"
    />

    <activity-detail-cashback
      v-else-if="showCashBackDetails"
      :content="content"
    />

    <activity-detail-cash
      v-if="showCashDetails"
      :content="content"
      :loading="detailsLoading"
    />

    <activity-detail-qr
      v-if="showQrPayDetails"
      :content="content"
      :loading="detailsLoading"
    />

    <div class="-mx-5 mt-5">
      <points-panel
        v-if="isPointsEarnShow"
        :total="pointsEarned"
        :completed="isCompleted"
      />
      <cashback-panel :total="cashbackRebateTotal" :completed="isCompleted" />
    </div>

    <div class="mt-auto py-5">
      <base-button
        v-if="showPrintButton"
        full-width
        look="outlined-color"
        class="mt-10"
        @click="print"
      >
        Print receipt
      </base-button>
    </div>

    <portal to="print-destination">
      <div>
        <div class="table w-full">
          <div class="table-cell w-2/4 align-top">
            <app-logo class="block max-h-[60px] max-w-40" />
          </div>
          <div class="table-cell w-2/4 align-top">
            <div class="float-right text-right leading-snug">
              <div>
                <strong>
                  {{ ewalletLabels.ewallet }} transaction processed by
                </strong>
                <br />
                EonX Services
              </div>
              <div>ABN: 39 615 958 873</div>
            </div>
          </div>
        </div>

        <div class="px-0 pb-10 pt-12 text-center font-bold uppercase">
          <h1>Tax Invoice</h1>
        </div>

        <details-order-print v-if="showOrderDetails" :content="content" />

        <details-cashback-print
          v-else-if="showCashBackDetails"
          :content="content"
        />

        <details-cash-print v-else-if="showCashDetails" :content="content" />

        <details-qr-print v-else-if="showQrPayDetails" :content="content" />
      </div>
    </portal>
  </div>
</template>
