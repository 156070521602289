<script>
import { formatDate } from '/~/utils/format/date'
import { formatDollar } from '/~/utils/format/money'
import { formatPhone } from '/~/utils/format/string'
import { PaymentMethodType } from '/~/composables/payment-methods/payment-methods-types'

export default {
  name: 'activity-details-qr-print',
  props: {
    content: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    return {
      formatDate,
      formatDollar,
      formatPhone,
      PaymentMethodType,
    }
  },
  computed: {
    status() {
      return this.content.status?.toLowerCase()
    },
    statusComplete() {
      return this.status === 'completed'
    },
    paymentMethods() {
      return this.content.paymentMethods || []
    },
    pointsMonetaryValueRedeemed() {
      if (this.paymentMethods.length) {
        const pointsPaymentMethod = this.paymentMethods.find(
          (method) => method.type === this.PaymentMethodType.points
        )

        if (pointsPaymentMethod) {
          return pointsPaymentMethod.finalAmount
        }
      }
      return ''
    },
    subtotal() {
      return this.content.subtotal ?? 0
    },
    total() {
      return this.content.total ?? 0
    },
  },
}
</script>

<template>
  <div>
    <div class="space-y-[15px]">
      <div class="border-b pb-[15px] text-sm leading-7">
        <p>
          <strong>Date:</strong>
          {{ formatDate('daymonthyearlongtime', content.createdAt) }}
        </p>
        <p>
          <strong>Order no.</strong>
          {{ content.number }}
        </p>
        <p>
          <strong class="mr-1">Status:</strong>
          <span v-if="statusComplete" class="text-fg-success">Paid</span>
          <span v-else class="text-fg-warning">Processing</span>
        </p>
      </div>
    </div>

    <table class="w-full">
      <tr class="border-b">
        <th class="py-[15px] pl-0 text-left">Item</th>
        <th class="py-[15px] text-left">Price</th>
        <th class="py-[15px] text-left">Quantity</th>
        <th class="py-[15px] text-right">Total</th>
      </tr>
      <tr v-if="paymentMethods[0]" class="border-b">
        <th class="py-[15px] pl-0 text-left">
          {{ paymentMethods[0].reference }}
        </th>
        <th class="w-1/4 py-[15px] text-left">
          {{ formatDollar(subtotal) }}
        </th>
        <th class="py-[15px] text-left">
          {{ paymentMethods[0].amount }}
        </th>
        <th class="py-[15px] text-right">
          {{ formatDollar(total) }}
        </th>
      </tr>
      <tr>
        <td colspan="3" class="pt-5 pr-2.5 font-bold">Sub total</td>
        <td class="pt-5 text-right">
          {{ formatDollar(subtotal) }}
        </td>
      </tr>
      <tr class="sub">
        <td colspan="3" class="py-2.5 pr-2.5 font-bold">Total amount paid</td>
        <td class="py-2.5 text-right text-2xl">
          <strong>{{ formatDollar(total) }}</strong>
        </td>
      </tr>
      <template v-if="paymentMethods.length">
        <tr class="sub">
          <td class="py-2.5" colspan="3">
            <h3>Payment methods</h3>
          </td>
          <td class="py-2.5 text-right" />
        </tr>
        <tr v-for="method of paymentMethods" :key="method.id" class="sub">
          <td class="py-2.5 pr-2.5" colspan="3">
            <div>
              {{ method.type }}
            </div>
          </td>
          <td class="py-2.5 pl-5 text-right">
            {{ formatDollar(-method.total) }}
          </td>
        </tr>
      </template>
    </table>
  </div>
</template>
