<script>
import { getCardTypeByNumber } from '@eonx-com/payment-elements'
import get from 'lodash-es/get'
import { formatDate } from '/~/utils/format/date'
import { formatDollar } from '/~/utils/format/money'
import { useCms } from '/~/composables/cms/use-cms'
import { PaymentMethodType } from '/~/composables/payment-methods/payment-methods-types'
import DrawerActivityDetailsItem from '../drawer-activity-details-item.vue'

export default {
  name: 'drawer-activity-detail-qr',
  components: {
    DrawerActivityDetailsItem,
  },
  props: {
    content: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    const { ewalletLabels } = useCms()

    return {
      ewalletLabels,
      formatDate,
      formatDollar,
      PaymentMethodType,
    }
  },
  computed: {
    paymentMethods() {
      const result = (this.content?.paymentMethods || []).map((method) => {
        const result = { ...method }

        switch (method.type) {
          case this.PaymentMethodType.creditCard:
            result.text = `* ${method.number.slice(-4)}`
            result.icon = `cards/${get(
              getCardTypeByNumber(method.number),
              'type'
            )}`
            break
          case this.PaymentMethodType.bankAccount:
            result.text = `Bank Account ${method.bsb}`
            break
          case this.PaymentMethodType.couponCode:
            result.text = `${method.number} - $${method.amount} Coupon`
            break
          case this.PaymentMethodType.eWallet: {
            result.text = this.ewalletLabels.ewalletCash
            result.icon = 'ewallet'
          }
        }

        return result
      })

      return result.length > 0 ? result : null
    },
    activityItems() {
      let value = ''
      let state = 'warning'

      if (this.content.status === 'completed') {
        value = 'Paid'
        state = 'success'
      } else if (this.content.status === 'canceled') {
        value = 'Canceled'
        state = 'error'
      } else if (this.content.status === 'pending') {
        value = 'Pending'
      } else {
        value = 'Order Pending'
      }
      return [
        {
          title: 'Reference',
          value: this.content.paymentMethods[0]?.reference,
        },
        {
          title: 'Transaction No.',
          value: this.content.number,
        },
        {
          title: 'Date',
          value: this.formatDate('daymonthyear', this.content.createdAt),
        },
        {
          title: 'Status',
          value,
          state,
        },
        {
          title: 'Payment',
          value: this.paymentMethods,
        },
        {
          title: 'Payee',
          value: this.content.payee,
        },
        {
          title: 'Payer',
          value: this.content.payer,
        },
        {
          title: 'Amount',
          value: this.formatDollar(this.content.total),
        },
        {
          title: 'Refund Code',
          value: this.content.refundCode,
        },
        {
          title: 'Refund Expiry',
          value: this.formatDate('daymonthyear', this.content.refundExpiry),
        },
      ]
    },
  },
}
</script>

<template>
  <drawer-activity-details-item :items="activityItems" />
</template>
