<script>
import { getCardTypeByNumber } from '@eonx-com/payment-elements'
import get from 'lodash-es/get'
import { formatDate } from '/~/utils/format/date'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BasePlaceholder from '/~/components/base/placeholder/base-placeholder.vue'
import { useCms } from '/~/composables/cms/use-cms'
import { PaymentMethodType } from '/~/composables/payment-methods/payment-methods-types'
import ActivityDetailOrderList from './drawer-activity-detail-order-list.vue'

export default {
  name: 'drawer-activity-detail-order',
  components: {
    ActivityDetailOrderList,
    BasePlaceholder,
    BaseIcon,
  },
  props: {
    content: {
      type: Object,
      default: () => ({}),
    },
    loading: {
      type: Boolean,
      default: false,
    },
    detailsLoading: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { ewalletLabels } = useCms()

    return {
      ewalletLabels,
      formatDate,
      PaymentMethodType,
    }
  },
  computed: {
    status() {
      return this.content?.status?.toLowerCase()
    },
    statusComplete() {
      return this.status === 'completed'
    },
    showDeliveryData() {
      return this.content.shipping_address && this.isAnyPhysicalProduct
    },
    isAnyPhysicalProduct() {
      return this.content?.items?.some((item) => item.physical)
    },
    paymentMethods() {
      return (this.content?.paymentMethods || []).map((method) => {
        const result = { ...method }

        switch (method.type) {
          case this.PaymentMethodType.creditCard:
            result.text = `* ${method.number.slice(-4)}`
            result.icon = `cards/${get(
              getCardTypeByNumber(method.number),
              'type'
            )}`
            break
          case this.PaymentMethodType.bankAccount:
            result.text = `Bank Account ${method.bsb}`
            break
          case this.PaymentMethodType.couponCode:
            result.text = `${method.number} - $${method.amount} Coupon`
            break
          case this.PaymentMethodType.eWallet: {
            result.text = this.ewalletLabels.ewalletCash
            result.icon = 'ewallet'
          }
        }

        return result
      })
    },
  },
}
</script>

<template>
  <div>
    <div :class="$style.info" data-cy="info">
      <div :class="$style.column">
        <div :class="$style.order">
          <div :class="$style.status">Order No.</div>
          <div :class="[$style.data]" data-cy="number">
            {{ content.number }}
          </div>
        </div>
        <div :class="$style.order">
          <div :class="$style.status">Date</div>
          <div :class="$style.data">
            {{ formatDate('daymonthyear', content.paidAt) }}
          </div>
        </div>

        <div v-if="paymentMethods.length" :class="$style.order">
          <div :class="$style.status">Payment</div>

          <div class="flex flex-col">
            <div
              v-for="(pm, idx) in paymentMethods"
              :key="idx"
              :class="[$style.data, $style.dataFlex]"
            >
              <div
                v-if="pm.icon"
                :class="[
                  'mr-2.5 flex items-center',
                  pm.type === PaymentMethodType.eWallet ? 'w-6' : 'w-[35px]',
                ]"
              >
                <base-icon size="auto" :svg="pm.icon" />
              </div>
              <span>{{ pm.text }}</span>
            </div>
          </div>
        </div>

        <div v-if="status" :class="$style.order" data-test="order-status">
          <div :class="$style.status">Status</div>
          <template v-if="detailsLoading">
            <base-placeholder
              :rows="1"
              type="text"
              :class="$style.statusPlaceholder"
            />
          </template>
          <template v-else-if="statusComplete">
            <div :class="[$style.data, $style.dataFlex, $style.dataApproved]">
              <base-icon
                svg="plain/check"
                size="md"
                :class="$style.approvedIcon"
              />
              Approved
            </div>
          </template>
          <template v-else>
            <div
              :class="[$style.data, $style.dataFlex, $style.dataPending]"
              data-cy="data-pending"
            >
              <base-icon svg="warning" size="md" :class="$style.warningIcon" />
              {{ isAnyPhysicalProduct ? 'Pending Delivery' : 'Order Pending' }}
            </div>
          </template>
        </div>
      </div>
      <div :class="$style.column" data-cy="column">
        <div v-if="content.billing_address" :class="$style.order">
          <div :class="[$style.status, $style.statusNarrow]">Billing</div>
          <div :class="[$style.data, $style.billing]">
            <div>
              {{ content.billing_address.first_name }}
              {{ content.billing_address.last_name }}
            </div>
            <div>
              {{ content.billing_address.street_address }}
            </div>
            <div>
              {{ content.billing_address.suburb }}
              {{ content.billing_address.postcode }}
              <div class="uppercase">
                {{ content.billing_address.state }}
              </div>
            </div>
          </div>
        </div>
        <div v-if="showDeliveryData" :class="$style.order">
          <div :class="$style.status">Delivery</div>
          <div :class="$style.data">
            <div>
              {{ content.shipping_address.first_name }}
              {{ content.shipping_address.last_name }}
            </div>
            <div>
              {{ content.shipping_address.street_address }}
            </div>
            <div>
              {{ content.shipping_address.suburb }}
              {{ content.shipping_address.postcode }}
              <div class="uppercase">
                {{ content.shipping_address.state }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <activity-detail-order-list
      :content="content"
      :loading="loading || detailsLoading"
    />
  </div>
</template>

<style lang="scss" module>
.info {
  @apply mb-px w-full text-sm leading-7;
}

.column {
  width: 100%;
}

.productTypeIcon {
  margin-right: 0.5rem;
  font-size: 0.875rem;
}

.productTypeDigital {
  background-color: var(--color-digital);
}

.order {
  @apply flex pb-2.5;
}

.status {
  min-width: 4.625rem;
  margin-right: 1rem;
  margin-bottom: 0.125rem;
}

.data {
  min-width: 0;
  font-weight: bold;

  :global(.icon) {
    margin-right: 0.3125rem;
  }
}

.dataFlex {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.dataWalletIcon {
  margin-right: 0.5rem;
}

.dataCardIcon {
  @apply mr-2.5 w-10;
}

.dataPending {
  position: relative;
  color: var(--color-fg-warning);
  white-space: nowrap;
}

.dataApproved {
  position: relative;
  color: var(--color-fg-success);
}

.dataCancelled {
  color: var(--color-fg-error);
}

.warningIcon {
  @apply mr-2.5 text-base;
}

.approvedIcon {
  @apply mr-2.5 flex items-center justify-center rounded-full bg-fg-success text-white;

  :global {
    .base-icon__svg {
      @apply h-2.5 w-2.5 text-sm;
    }
  }
}

.verifyButton {
  margin-top: 1.25rem;
}

.statusPlaceholder {
  @apply mt-[-3px] w-full;
}
</style>
